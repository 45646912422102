<template>
  <BaseModal
    name="modal-business"
    id="modal-business"
    idModal="modal-business"
    size="md"
    @shown="openModal"
    :title="reasonLoss ? 'Negócio perdido' : 'Negócio ganho'"
  >
    <div ref="reasonsScroll" class="content-all">
      <div>
        <div
          class="check-container"
          @click="selectOption(index, item)"
          v-for="(item, index) in reasons"
          :key="index"
        >
          <div
            class="check-itself"
            :class="{ 'selected-check': indexSelected == index }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" viewBox="0 0 256 256"><path d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path></svg>
          </div>
          <span class="check-label">
            {{ item.reason }}
          </span>
        </div>
      </div>
      <div class="check-container2">
        <div
          class="check-container3"
          @click="(isOther = true), (indexSelected = -1)"
        >
          <div class="check-itself" :class="{ 'selected-check': isOther }">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" viewBox="0 0 256 256"><path d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path></svg>
          </div>
          <span class="check-label"> Outro motivo (escreva) </span>
        </div>
        <b-collapse v-model="isOther">
          <div style="height: 10px"></div>
          <b-form-textarea
            no-resize
            v-model="otherReason"
            rows="3"
            placeholder="O lead foi perdido porque..."
          ></b-form-textarea>
        </b-collapse>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>

      <BaseButton :variant="reasonLoss ? 'danger' : 'primary'" class="mr-4" @click="loading ? '' : reasonLead()" :class="loading ? 'loading-btn' : ''">
        Confirmar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import ActivitieService from "@/services/resources/ActivitieService";
const serviceActivitie = ActivitieService.build();
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

export default {
  props: ["lead_id", "is_pipeline", "pipeline_id", "reasonType"],
  data() {
    return {
      isOther: false,
      otherReason: "",
      reasons: [],
      indexSelected: -1,
      loading: false,
      reasonsSelected:'',
      page: 0
    };
  },
  computed: {
    reasonLoss() {
      return this.reasonType === 'loss';
    }
  },
  methods: {
    selectOption(index, resason) {
      this.isOther = false
      this.indexSelected = index
      this.reasonsSelected = resason
    },
    openModal(page = 1) {
      this.reasons = [];
      this.indexSelected = -1;
      this.otherReason = "";
      this.getReasons();
    },
    getReasons(page = 1) {
      const path = `${this.reasonLoss ? 'reasons/list' : 'reasons/gain/list'}?page=${page}`
      serviceSettings.read(path).then((resp) => {
        const data = resp.data ?? resp;
        if(page > 1 && !data.length){
          this.$refs.reasonsScroll.removeEventListener('scroll', this.handleScroll);
          return;
        }
        let concatReasons = this.reasons.concat(data).sort((a, b) => a.position - b.position);
        this.reasons = concatReasons;

        if(page === 1){
          this.page = 2;
          this.$refs.reasonsScroll.addEventListener('scroll', this.handleScroll);
          return;
        }
        this.page += 1;
      });
    },
    reasonLead() {
      if (
        (!this.isOther && this.indexSelected < 0) ||
        (this.isOther && !this.otherReason)
      ) {
        this.$bvToast.toast("É necessário preencher o motivo", {
          title: this.reasonLoss ? "Negócio perdido" : "Negócio ganho",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      var reason;
      if (this.isOther) {
        reason = this.otherReason
      } else  {
        reason = this.reasonsSelected;
      }
      var data = {
        id: "lead/change-status",
        lead_id: this.lead_id,
        reason: reason.reason ?? this.otherReason,
        status: this.reasonLoss ? "inactive" : "done",
        pipeline_id: this.pipeline_id,
      };
      this.loading = true
      serviceActivitie.createId(data).then(() => {
        this.loading = false
        this.$bvToast.toast(this.reasonLoss ? "Lead perdido" : "Lead ganho", {
          title: this.reasonLoss ? "Negócio perdido" : "Negócio ganho",
          variant: "info",
          autoHideDelay: 300,
          appendToast: true,
        });

        this.$bvModal.hide("modal-business");
        if (!this.is_pipeline) {
          this.$parent.fetchLead(this.lead.id);
          return;
        }

        this.$emit("update");
      }). catch(error => {
        this.loading = false
        this.$bvToast.toast("Erro ao salvar motivo de perda", {
          title: this.reasonLoss ? "Negócio perdido" : "Negócio ganho",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
      });
    },
    handleScroll(){
      const el = this.$refs.reasonsScroll;
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        this.getReasons(this.page);
      }
    }
  },
  beforeDestroy() {
    this.$refs.reasonsScroll.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
span {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: var(--gray03);
  line-height: 1.5;
}
.content-all {
  min-height: 35vh;
  max-height: 35vh;
  overflow-x: auto;
  scrollbar-gutter: stable;
  padding: 0 15px;
  user-select: none;
}
::-webkit-scrollbar-thumb {
  background-color: var(--white-dark);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}
.check-container {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid var(--white-medium);
  border-radius: 10px;
  padding: 10px;
}
.check-itself {
  height: 20px;
  border: 1px solid var(--white-medium);
  aspect-ratio: 1;
  border-radius: 5px;
  transition: all 0.2s;
  color: white;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
  }
  &:hover {
    border-color: var(--greenn);
  }
  &.selected-check {
    background-color: var(--greenn) !important;
  }
}
.check-container2 {
  border: 1px solid var(--white-medium);
  border-radius: 10px;
  padding: 10px;
}
.check-container3 {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
}
</style>
