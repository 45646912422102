<template>
  <BaseModal
    name="add-tag"
    id="add-tag"
    idModal="add-tag"
    size="md"
    @shown="openModal"
    :title="id_edit && !origem_dadosTag ? 'Atualizar tag' : 'Adicionar tag'"
  >
    <div v-if="!loading">
      <div class="content-tags">
        <div>
          <label>Nome</label>
          <b-form-input
            v-model="nome"
            name="nome"
            v-validate="'required|max:50'"
            :style="nome.length > 50 ? 'border-color: red !important;' : ''"
          ></b-form-input>
          <b-form-invalid-feedback :state="!errors.has('nome')" style="font-size: 12px; margin-top: 0;">
            {{ nome.length > 50 ? 'O nome da tag deve ter no máximo 50 caracteres' : 'Insira um nome' }}
          </b-form-invalid-feedback>
        </div>
        <div>
          <label>Cor</label>
          <div class="mt-1 colors">
            <div v-for="color in colors" :class="`${color} ${color == selectedColor ? 'selected-color' : ''}`" @click="selectedColor = color">✓</div>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        v-if="id_edit && !origem_dadosTag"
        variant="danger"
        class="mr-2"
        @click="deleteTag"
      >
        Deletar tag
      </BaseButton>
      <BaseButton variant="link-info" class="mr-2" @click="cancel" v-else>
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        {{ id_edit ? "Atualizar" : "Cadastrar" }} Tag
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import TagService from "@/services/resources/TagService";
import Vue from "vue";
const serviceTag = TagService.build();

export default {
  props: ["dadosTag", "origem_dadosTag"],
  data() {
    return {
      loading: null,
      nome: "",
      id_edit: null,
      selectedColor: '',
      colors: ["primary","info","warning","danger", "default"],
    };
  },
  computed: {},
  methods: {
    deleteTag() {
      Vue.swal({
        title: "Gestão de tags",
        text: `Deseja deletar a tag selecionada?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: `/delete/${this.id_edit}`,
          };
          serviceTag
            .destroy(data)
            .then((resp) => {
              this.loading = false;
              this.$emit("add-tag");
              this.$bvModal.hide("add-tag");
              this.$bvToast.toast(resp.message, {
                title: "Gestão de tags",
                variant:
                  resp.message === "Tag deletada com sucesso!"
                    ? "info"
                    : "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              // this.$store.dispatch("fetchTags");
            })
            .catch(() => {
              this.$bvToast.toast("Erro ao deletar tag", {
                title: "Gestão de tags",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
              this.$emit("add-tag");
              this.$bvModal.hide("add-tag");
            });
        }
      });
    },
    openModal() {
      this.$nextTick(() => {
        this.errors.clear();
        this.$nextTick(() => {
          this.$validator.reset();
        });
      });
      if (this.dadosTag) {
        this.nome = this.dadosTag.name;
        this.selectedColor = this.dadosTag.color
        this.id_edit = this.dadosTag.id;
      } else {
        this.nome = "";
        this.selectedColor = 'primary';
        this.id_edit = null;
      }
    },
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            name: this.nome,
            description: '',
            color: this.selectedColor,
          };

          if (this.id_edit) {
            data.id = `edit/${this.id_edit}`;
            serviceTag
              .update(data)
              .then((resp) => {
                this.$bvToast.toast("Tag atualizada com sucesso", {
                  title: this.origem_dadosTag ? "Pipeline" : "Gestão de tags",
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("add-tag", resp.tag);
                this.$bvModal.hide("add-tag");
                // this.$store.dispatch("fetchTags");
              })
              .catch((err) => {
                if (
                  err.response.data.message ==
                  "The name has already been taken."
                ) {
                  this.$bvToast.toast("Essa tag já está sendo usada", {
                    title: "Gestão de tags",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                } else {
                  this.$bvToast.toast("Erro ao atualizar tag", {
                    title: "Gestão de tags",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                }

                this.$bvModal.hide("add-tag");
              })
              .finally(() => {
                this.loading = false;
              });
            return;
          }

          serviceTag
            .create(data)
            .then((resp) => {
              this.$bvToast.toast("Tag criada com sucesso", {
                title: "Gestão de tags",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$emit("add-tag");
              this.$emit("addTagToArray", resp.tag);
              this.$bvModal.hide("add-tag");
              // this.$store.dispatch("fetchTags");
            })
            .catch((err) => {
              if (
                err.response.data.message == "The name has already been taken."
              ) {
                this.$bvToast.toast("Essa tag já está sendo usada", {
                  title: "Gestão de tags",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              } else {
                this.$bvToast.toast("Erro ao atualizar tag", {
                  title: "Gestão de tags",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
              this.$bvModal.hide("add-tag");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content-tags {
  display: flex;
  flex-direction: column;
  height: 175px;
  justify-content: space-between;
}
.colors {
  display: flex;
  gap: 10px;
  > div {
    width: 45px;
    aspect-ratio: 1;
    border-radius: 7px;
    cursor: pointer;
    transition: all .3s;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    &:hover {
      box-shadow: inset 0 0 0px 2.5px #fff;
    }
  }
  .selected-color {
    box-shadow: inset 0 0 0px 2.5px #fffb, 2px 2px 3px 1px #0002;
    color: #fffb !important;
  }
}
.info {
    color: var(--bluee);
    background: var(--bluee);
    border-color: var(--bluee) !important;
}

.danger {
    color: var(--red);
    background: var(--red);
    border-color: var(--red) !important;
}

.warning {
    color: var(--orange);
    background: var(--orange);
    border-color: var(--orange) !important;
}

.primary {
    color: var(--greenn-light);
    background: var(--greenn-light);
    border-color: var(--greenn-light) !important;
}

.success {
    color: var(--bluee);
    background: var(--bluee);
    border-color: var(--bluee) !important;
}

.default {
    color: rgb(37, 37, 37);
    background: rgb(37, 37, 37);
    border-color: rgb(37, 37, 37) !important;
}
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
