<template>
  <BaseModal
    name="ModalPipelineTagAdicionar"
    id="ModalPipelineTagAdicionar"
    idModal="ModalPipelineTagAdicionar"
    size="xl"
    title="Adicionar board"
    @shown="isOpen()"
  >
    <div style="display: grid; grid-template-columns: 1fr 290px; gap: 15px">
      <div class="input-tag">
        <img src="@/assets/img/icons/search.svg" class="Pointer searchIcon" />
        <input
          type="text"
          v-model="tagSearch"
          placeholder="Pesquise por uma tag"
        />
      </div>
      <div class="grid-colors">
        <div
          class="primary"
          :class="
            color_selected == 'primary' ? 'selected-color' : 'unselected-color'
          "
          @click="
            color_selected == 'primary'
              ? (color_selected = '')
              : (color_selected = 'primary'),
              filterSearchTagsUpdate()
          "
        ></div>
        <div
          class="info"
          :class="
            color_selected == 'info' ? 'selected-color' : 'unselected-color'
          "
          @click="
            color_selected == 'info'
              ? (color_selected = '')
              : (color_selected = 'info'),
              filterSearchTagsUpdate()
          "
        ></div>
        <div
          class="danger"
          :class="
            color_selected == 'danger' ? 'selected-color' : 'unselected-color'
          "
          @click="
            color_selected == 'danger'
              ? (color_selected = '')
              : (color_selected = 'danger'),
              filterSearchTagsUpdate()
          "
        ></div>
        <div
          class="warning"
          :class="
            color_selected == 'warning' ? 'selected-color' : 'unselected-color'
          "
          @click="
            color_selected == 'warning'
              ? (color_selected = '')
              : (color_selected = 'warning'),
              filterSearchTagsUpdate()
          "
        ></div>
        <div
          class="default"
          :class="
            color_selected == 'default' ? 'selected-color' : 'unselected-color'
          "
          @click="
            color_selected == 'default'
              ? (color_selected = '')
              : (color_selected = 'default'),
              filterSearchTagsUpdate()
          "
        ></div>
      </div>
    </div>
    <div class="limit">
      <div class="grid">
        <div v-for="(item, index) in filterSearchTags" :key="index">
          <div
            class="single-tag"
            :class="`${item.color} ${
              item.id == selectedId ? 'selected-tag' : ''
            } ${
              (getSelectedPipeline.includes(item.id.toString()) &&
                item.id != selectedId) ||
              tag_saida == item.id
                ? 'disabled-tag'
                : ''
            }`"
            @click="
              getSelectedPipeline.includes(item.id.toString()) ||
              tag_saida == item.id
                ? ''
                : ((selectedId = item.id), (selectedTagID = item.id))
            "
          >
            <h4 class="nome">
              {{
                item.name.length > 22
                  ? item.name.substr(0, 22) + "..."
                  : item.name
              }}
            </h4>
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-2" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton
        variant="primary"
        @click="onSubmit"
        :disabled="loading || selectedId == null"
      >
        Adicionar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import PipelineService from "@/services/resources/PipelineService";
const servicePipeline = PipelineService.build();

import Vue from "vue";

export default {
  props: ["selectedPipeline", "getSelectedPipeline", "tags_props"],
  data() {
    return {
      titulo: "",
      filterSearchTags: "",
      tagSearch: "",
      tag: [],
      loading: false,
      selected_form: [],
      array_form_boards: [],
      selectedId: null,
      selectedTagID: null,
      tag_saida: undefined,
      pipelines: [],
      color_selected: "",
    };
  },
  watch: {
    tagSearch: "filterSearchTagsUpdate",
  },
  methods: {
    filterSearchTagsUpdate() {
      this.filterSearchTags = this.tags_props?.filter((item) => {
        const nameMatches = item.name
          ?.toLowerCase()
          .includes(this.tagSearch.toLowerCase());
        const colorMatches =
          !this.color_selected ||
          item.color?.toLowerCase().includes(this.color_selected);
        return nameMatches && colorMatches;
      });
    },
    isOpen() {
      this.selectedId = -1;
      this.tagSearch = "";
      this.color_selected = "";
      this.filterSearchTags = this.tags_props;
    },
    onSubmit() {
      if (!this.selectedTagID) {
        this.$bvToast.toast("Obrigatório escolher uma tag", {
          title: "CRM",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      this.loading = true;
      var tags = Array.from(this.getSelectedPipeline);
      tags.splice(tags.length - 1, 0, this.selectedTagID.toString());

      var data = {
        id: `edit/${this.selectedPipeline.id}`,
        tags: tags,
      };

      servicePipeline
        .update(data)
        .then(() => {
          this.$bvToast.toast("CRM alterado com sucesso", {
            title: "CRM",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.loading = false;
          this.$emit("update", tags);
          this.$bvModal.hide("ModalPipelineTagAdicionar");
        })
        .catch((err) => {
          this.$bvToast.toast("Erro ao alterar CRM", {
            title: "CRM",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.input-tag {
  width: 100%;
  padding-right: 10px;
  position: relative;
  input {
    padding-left: 42px !important;
    width: 100%;
  }
  .searchIcon {
    position: absolute;
    height: 15px;
    top: 18px;
    left: 18px;
  }
}

.null {
  background-color: #c2c2c2;
}
.info {
  // .dot {
  background-color: var(--bluee);
  // }
}
.danger {
  // .dot {
  background-color: var(--red);
  // }
}
.warning {
  // .dot {
  background-color: var(--orange);
  // }
}
.primary {
  // .dot {
  background-color: var(--greenn-light);
  // }
}
.success {
  // .dot {
  background-color: #4b2cbd;
  // }
}
.default {
  // .dot {
  background-color: var(--gray01);
  // }
}
.img {
  cursor: pointer;
}

.single-tag {
  transition: all 0.3s;
  height: 42px;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  .dot {
    height: 10px;
    width: 10px;
    border-radius: 20px;
    margin-top: 2px;
  }
  .nome {
    color: inherit;
    font-weight: 600;
    font-size: 14px;
    margin: 0 !important;
    line-height: 1;
  }
}
.selected-color {
  box-shadow: inset 0px 0px 0px 25px #fff0, 0px 0px 3px 1px #0005;
}
.unselected-color {
  box-shadow: inset 0px 0px 0px 25px #fff6, 0px 0px 3px 1px #0000;
  &:hover {
    filter: brightness(0.95);
  }
}
.selected-tag {
  box-shadow: inset 0px 0px 0px 25px #fff6, 0px 0px 3px 1px #0005;
}
.disabled-tag {
  cursor: default !important;
  background-color: #c2c2c2;
  color: #f7f7f7;
}
.flex-titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex {
  display: flex;
  align-items: center;
  gap: 25px;
  position: fixed;
  right: 50px;
}
.limit {
  min-height: 55vh;
  max-height: 55vh;
  overflow-y: auto;
}
.grid-colors {
  display: flex;
  width: fit-content;
  margin-bottom: 12px;
  gap: 10px;
  div {
    transition: all 0.3s;
    aspect-ratio: 1;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--white-dark);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

* {
  user-select: none;
}
.b-flex {
  display: flex;
  gap: 6px;
}
</style>
